import React, { useContext, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import * as actions from "../../store/actions"

import { FormatDate, Link, useI18next } from "../../i18n"

import gql from "graphql-tag"
import { useMutation, useQuery } from "@apollo/client"

import { buying_group, user_in_buying_group } from "../../API"
import { GET_PERSONAL_DETAILS, UPDATE_PERSONAL_DETAILS, createChosedVariant, updateChosedVariant } from "../../graphql.my/queries"
import { deleteChosedVariant, updateUserInBuyingGroup } from "../../graphql/mutations"

import { firstName, email, readPrivacy, mobile, state } from "../../forms/fields/user"
import FormGenerator from "../../Layout/Forms/FormGenerator"
import InContentTopBar from "./InContentTopBar"
import { Icon } from "../../Layout/Components"

import { buyingGroupData } from "../crmWpBuyingGroup"
import { FormPropertiesData } from "../../Layout/Forms/FormDataType"

import _ from "lodash"

import { differenceInCalendarDays, isPast, isFuture } from "date-fns"
import custom_userChoosingAuto from "./custom-forms-jsons/userChoosing-auto.json"
import custom_userConfirmationAuto from "./custom-forms-jsons/userConfirmation-auto.json"
import { parseISO } from "date-fns/esm"

type props = {
  currentStep: number
  rdbGroupData: buyingGroupData
  userInGroupData: user_in_buying_group | null
  setCurrentStep: Function
  scrollToTop: Function
  setIsLoading: Function
  userInGroupDataRefetch: Function
}

const Forms: React.FC<props> = ({ currentStep, rdbGroupData, userInGroupData, setCurrentStep, scrollToTop, setIsLoading, userInGroupDataRefetch }) => {
  const { t } = useI18next()
  const dispatch = useDispatch()

  const userId = useSelector((state) => state.auth.payload?.userData?.userId)
  const subscribedUntil = useSelector((state) => state.auth.payload.userData?.subscribedUntil)
  const [isFormVisible, setIsFormVisible] = useState(false)
  const [preForm, setPreForm] = useState("" as any)
  const [afterForm, setAfterForm] = useState("" as any)
  const [form, setForm] = useState({})
  const [formTitle, setFormTitle] = useState("")
  const [formDescription, setFormDescription] = useState("")
  const [formButton, setFormButton] = useState("")
  const [isFormDisabled, setIsFormDisabled] = useState(false)
  const [formData, setFormData] = useState({} as any)

  let registrationStartDate = rdbGroupData.registrationStartDate != null ? parseISO(rdbGroupData.registrationStartDate) : null
  let registrationEndDate = rdbGroupData.registrationEndDate != null ? parseISO(rdbGroupData.registrationEndDate) : null
  let participationStartDate = rdbGroupData.participationStartDate != null ? parseISO(rdbGroupData.participationStartDate) : null
  let participationEndDate = rdbGroupData.participationEndDate != null ? parseISO(rdbGroupData.participationEndDate) : null
  let choosingStartDate = rdbGroupData.choosingStartDate != null ? parseISO(rdbGroupData.choosingStartDate) : null
  let choosingEndDate = rdbGroupData.choosingEndDate != null ? parseISO(rdbGroupData.choosingEndDate) : null
  let confirmationStartDate = rdbGroupData.confirmationStartDate != null ? parseISO(rdbGroupData.confirmationStartDate) : null
  let confirmationEndDate = rdbGroupData.confirmationEndDate != null ? parseISO(rdbGroupData.confirmationEndDate) : null

  //---------------------------------------------------------------------------------------------------------------------------------------------------------
  // Handle different kind of group by simulating other steps

  // uniteChooseAndConfirm groups have a unique step 4 from a Forms.tsx point of view ;-)
  const localCurrentStep = rdbGroupData.uniteChooseAndConfirm ? 4 : currentStep

  //---------------------------------------------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------------------------------------------
  // Data Layer

  let reachedStep = userInGroupData.confirmationDate ? 4 : userInGroupData.choosingDate ? 3 : userInGroupData.participationDate ? 2 : 1

  const [updateUserInGroup, { loading: updateUserInGroupLoading }] = useMutation(gql(updateUserInBuyingGroup), {
    onCompleted: () => {
      userInGroupDataRefetch()
      dispatch(
        actions.notificationAdd({
          message: "Nuove informazioni salvate",
          variant: "SmileIcon",
          duration: 30,
        })
      )
      scrollToTop()
    },
    onError: (error) => {
      dispatch(actions.notificationAdd({ message: t("API Error"), variant: "DangerIcon" }))
      console.log("API Error", error)
      return null
    },
  })

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  // Step 1

  // Update data after submit
  const [updateUserData, { loading: userDataLoading, error: mutationError }] = useMutation(gql(UPDATE_PERSONAL_DETAILS), {
    onError: (error) => {
      dispatch(actions.notificationAdd({ message: t("API Error"), variant: "DangerIcon" }))
      console.log("API Error", error, mutationError)
    },
  })

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  // Step 2

  const [createUserChosedVariant, { loading: createUserChosedVariantLoading }] = useMutation(gql(createChosedVariant), {
    onError: (error) => {
      dispatch(actions.notificationAdd({ message: t("API Error"), variant: "DangerIcon" }))
      console.log("API Error", error)
      return null
    },
  })

  const [updateUserChosedVariant, { loading: updateUserChosedVariantLoading }] = useMutation(gql(updateChosedVariant), {
    onError: (error) => {
      dispatch(actions.notificationAdd({ message: t("API Error"), variant: "DangerIcon" }))
      console.log("API Error", error)
      return null
    },
  })

  const [deleteUserChosedVariant, { loading: deleteUserChosedVariantLoading }] = useMutation(gql(deleteChosedVariant), {
    onError: (error) => {
      dispatch(actions.notificationAdd({ message: t("API Error"), variant: "DangerIcon" }))
      console.log("API Error", error)
      return null
    },
  })

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  // Step 3

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    setIsLoading(
      updateUserInGroupLoading || userDataLoading || createUserChosedVariantLoading || updateUserChosedVariantLoading || deleteUserChosedVariantLoading
    )
  }, [updateUserInGroupLoading, userDataLoading, createUserChosedVariantLoading, updateUserChosedVariantLoading, deleteUserChosedVariantLoading])

  //---------------------------------------------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------------------------------------------
  // Form Data Load

  useEffect(() => {
    let data: any = {}
    data.intention = userInGroupData.choosingDate ? (userInGroupData.notInterestInBuying ? "informazioni" : "acquisto") : null
    data.confirm = userInGroupData.confirmationDate ? (userInGroupData.definitelyNotInterestedInBuying ? "no" : "si") : null
    switch (localCurrentStep) {
      case 1:
        //---------------------------------------------------------------------------------------------------------------------------------------------------
        // Step 1

        break
      case 2:
        //---------------------------------------------------------------------------------------------------------------------------------------------------
        // Step 2

        if (userInGroupData.choosingDate && !userInGroupData.notInterestInBuying) {
          data.values = {}
          let productsCount = 0
          if (Array.isArray(userInGroupData.ChosedVariants)) {
            // Select chosedVariant with dateCanceled = null or the latest canceled one with cancelationPhase = "choosing"
            let i_latestCanceledChoosing = -1
            let latestChoosingDateCanceled = ""
            for (let i = 0; i < userInGroupData.ChosedVariants.length; i++) {
              const c = userInGroupData.ChosedVariants[i]
              if (!c.dateCanceled) {
                data.values[`products_${productsCount}`] = { variant: c.idVariant, ...JSON.parse(c.userChoosingCustomValues) }
                productsCount++
              }
              if (c.cancellationPhase == "confirmation" && c.dateCanceled > latestChoosingDateCanceled) {
                latestChoosingDateCanceled = c.dateCanceled
                i_latestCanceledChoosing = i
              }
            }
            // if non current (dateCanceled = null) then last canceled
            if (productsCount == 0 && i_latestCanceledChoosing != -1) {
              const c = userInGroupData.ChosedVariants[i_latestCanceledChoosing]
              data.values[`products_${productsCount}`] = { variant: c.idVariant, ...JSON.parse(c.userChoosingCustomValues) }
              productsCount++
            }
          }
        }

        break
      case 3:
        //---------------------------------------------------------------------------------------------------------------------------------------------------
        // Step 3
        if (userInGroupData.choosingDate && !userInGroupData.notInterestInBuying) {
          data.values = {}
          let productsCount = 0
          if (Array.isArray(userInGroupData.ChosedVariants)) {
            // Select chosedVariant with dateCanceled = null or the latest canceled one with cancelationPhase = "choosing"
            let i_latestCanceledChoosing = -1
            let latestChoosingDateCanceled = ""
            for (let i = 0; i < userInGroupData.ChosedVariants.length; i++) {
              const c = userInGroupData.ChosedVariants[i]
              if (!c.dateCanceled) {
                data.values[`products_${productsCount}`] = { variant: c.idVariant, ...JSON.parse(c.userChoosingCustomValues) }
                productsCount++
              }
              if (c.cancellationPhase == "confirmation" && c.dateCanceled > latestChoosingDateCanceled) {
                latestChoosingDateCanceled = c.dateCanceled
                i_latestCanceledChoosing = i
              }
            }
            // if non current (dateCanceled = null) then last canceled
            if (productsCount == 0 && i_latestCanceledChoosing != -1) {
              const c = userInGroupData.ChosedVariants[i_latestCanceledChoosing]
              data.values[`products_${productsCount}`] = { variant: c.idVariant, ...JSON.parse(c.userChoosingCustomValues) }
              productsCount++
            }
          }
        }
        break
      case 4:
        //---------------------------------------------------------------------------------------------------------------------------------------------------
        // Step unico
        if (userInGroupData.choosingDate && !userInGroupData.notInterestInBuying) {
          data.values = {}
          let productsCount = 0
          if (Array.isArray(userInGroupData.ChosedVariants)) {
            // Select chosedVariant with dateCanceled = null or the latest canceled one with cancelationPhase = "choosing"
            let i_latestCanceledChoosing = -1
            let latestChoosingDateCanceled = ""
            for (let i = 0; i < userInGroupData.ChosedVariants.length; i++) {
              const c = userInGroupData.ChosedVariants[i]
              if (!c.dateCanceled) {
                data.values[`products_${productsCount}`] = { variant: c.idVariant, ...JSON.parse(c.userChoosingCustomValues) }
                productsCount++
              }
              if (c.cancellationPhase == "confirmation" && c.dateCanceled > latestChoosingDateCanceled) {
                latestChoosingDateCanceled = c.dateCanceled
                i_latestCanceledChoosing = i
              }
            }
            // if non current (dateCanceled = null) then last canceled
            if (productsCount == 0 && i_latestCanceledChoosing != -1) {
              const c = userInGroupData.ChosedVariants[i_latestCanceledChoosing]
              data.values[`products_${productsCount}`] = { variant: c.idVariant, ...JSON.parse(c.userChoosingCustomValues) }
              productsCount++
            }
          }
        }
        break
    }
    setFormData(data)
  }, [localCurrentStep, userInGroupData])

  //---------------------------------------------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------------------------------------------
  // Form Generation

  useEffect(() => {
    let form: FormPropertiesData = {}

    switch (localCurrentStep) {
      case 1:
        //---------------------------------------------------------------------------------------------------------------------------------------------------
        // Step 1

        setIsFormVisible(reachedStep == 1)
        setIsFormDisabled(participationStartDate == null || isFuture(participationStartDate) || isPast(participationEndDate))

        setFormTitle("Partecipa")
        setFormDescription("Compila il form per partecipare a questo gruppo d'acquisto")
        setPreForm("")
        setAfterForm("")

        if (userInGroupData?.User?.mobile == null || userInGroupData?.User?.mobile == "") {
          form["mobile"] = {
            type: "string",
            ...mobile,
            required: true,
          }
        }
        if (!userInGroupData?.User?.state) {
          form["state"] = {
            ...state,
            required: true,
          }
        }

        setFormButton("Partecipa a questo gruppo d'acquisto")

        break
      case 2:
        //---------------------------------------------------------------------------------------------------------------------------------------------------
        // Step 2

        if (rdbGroupData.GoodOrServiceVariants) {
          setIsFormVisible(reachedStep > 1)
          setIsFormDisabled(
            isFuture(choosingStartDate) || isPast(choosingEndDate) || (userInGroupData.confirmationDate && userInGroupData.definitelyNotInterestedInBuying == 0)
          )

          setPreForm(
            <React.Fragment>
              <div style={{ marginBottom: "2rem" }}>
                <button
                  className={"btn btn--center " + (formData.intention == "acquisto" ? "btn--white" : "btn--secondary")}
                  onClick={() => {
                    if (!isFormDisabled) setFormData({ ...formData, intention: "acquisto" })
                  }}
                  style={{ width: "40%", marginRight: "2rem" }}
                  disabled={isFormDisabled}
                >
                  <Icon name={"far:" + (formData.intention == "acquisto" ? "check-circle" : "circle")} size="xs" style={{ marginRight: "1rem" }} />
                  Sono interessato/a all'acquisto
                </button>
                <button
                  className={"btn btn--center " + (formData.intention == "informazioni" ? "btn--white" : "btn--secondary")}
                  onClick={() => {
                    if (!isFormDisabled) setFormData({ ...formData, intention: "informazioni" })
                  }}
                  style={{ width: "40%" }}
                  disabled={isFormDisabled}
                >
                  <Icon name={"far:" + (formData.intention == "informazioni" ? "check-circle" : "circle")} size="xs" style={{ marginRight: "1rem" }} />
                  Voglio solo Informazioni
                </button>
              </div>
            </React.Fragment>
          )
          setAfterForm("")

          switch (formData.intention) {
            case "acquisto":
              setFormTitle("Acquisto")
              setFormDescription("Facci sapere che cosa vuoi acquistare.")
              setFormButton("Conferma la tua scelta")

              // TODO: Manage multiple good/services bought in the same group
              // for now fixed at 1
              for (let rows = 0; rows < 1; rows++) {
                const rowName = `products_${rows}`
                let variantUserChoosingCustomFieldsDef = {}

                if (formData.values && formData.values[rowName]?.variant) {
                  variantUserChoosingCustomFieldsDef = JSON.parse(
                    rdbGroupData.GoodOrServiceVariants?.find((g) => g.id == formData.values[rowName].variant)
                      .GoodOrService.GoodOrServiceType.userChoosingCustomFieldsDef.replace(/\n/g, "")
                      .replace(/\r/g, "")
                      .replace(/\t/g, "")
                  )
                }

                form[rowName] = {
                  type: "object",
                  properties: {
                    variant: {
                      title: "Mi interessa",
                      type: "string",
                      fieldType: "select",
                      fieldProperties: {
                        placeholder: "Select...",
                        options: rdbGroupData.GoodsOrServices?.map((g) => {
                          return g.Variants?.length > 0
                            ? {
                                label: g.GoodOrService.name,
                                options: g.Variants?.map((v) => ({
                                  value: v.id,
                                  title: v.name,
                                })),
                              }
                            : null
                        }).filter((e) => e != null) || [{ value: "0", title: "Errore, contatta l'amminiatratore del sito" }],
                      },
                      required: true,
                      errMessages: {
                        required: "E' necessario effettuare una scelta",
                      },
                    },

                    ...variantUserChoosingCustomFieldsDef,
                  },
                }
                //console.log("Form: ", form)
              }

              // Output stringified JSON custom forms
              // console.log(JSON.stringify(custom_userChoosingAuto))

              break
            case "informazioni":
              setFormTitle("Solo informazioni")
              setFormDescription(
                "Continuerai a ricevere informazioni su questo gruppo, ma noi sapremo che non hai intenzione di acquistare. Potrai modificare la tua scelta in qualsiasi momento."
              )
              setFormButton("Conferma la tua scelta")
              break
            default:
              setFormTitle("Intenzioni?")
              setFormDescription("Facci sapere quali sono le tue intenzioni per questo gruppo d'acquisto")
              form = null
          }

          if (userInGroupData.choosingDate != null) {
            const savedIntention = userInGroupData.notInterestInBuying ? "informazioni" : "acquisto"
            if (formData.intention == null) formData.intention = savedIntention

            if (savedIntention == formData.intention && savedIntention == "informazioni") {
              // Se intenzione = informazioni e non cambia non visualizzare il form
              form = null
            }
            setFormButton("Conferma la tua scelta")
          }

          //console.log(formData)
        }
        break
      case 3:
        //---------------------------------------------------------------------------------------------------------------------------------------------------
        // Step 3
        if (rdbGroupData.GoodOrServiceVariants) {
          setIsFormVisible(reachedStep > 2 && isPast(confirmationStartDate))
          setIsFormDisabled(
            isFuture(confirmationStartDate) ||
              isPast(confirmationEndDate) ||
              (userInGroupData.confirmationDate && userInGroupData.definitelyNotInterestedInBuying == 0)
          )

          if (formData.intention == "acquisto") {
            setPreForm(
              <React.Fragment>
                <div style={{ marginBottom: "2rem" }}>
                  <button
                    className={"btn btn--center " + (formData.confirm == "si" ? "btn--white" : "btn--secondary")}
                    onClick={() => {
                      if (!isFormDisabled) setFormData({ ...formData, confirm: "si" })
                    }}
                    style={{ width: "40%", marginRight: "2rem" }}
                    disabled={isFormDisabled || (userInGroupData.confirmationDate && userInGroupData.definitelyNotInterestedInBuying == 0)}
                  >
                    <Icon name={"far:" + (formData.confirm == "si" ? "check-circle" : "circle")} size="xs" style={{ marginRight: "1rem" }} />
                    Si
                  </button>
                  <button
                    className={"btn btn--center " + (formData.confirm == "no" ? "btn--white" : "btn--secondary")}
                    onClick={() => {
                      if (!isFormDisabled) setFormData({ ...formData, confirm: "no" })
                    }}
                    style={{ width: "40%" }}
                    disabled={isFormDisabled || (userInGroupData.confirmationDate && userInGroupData.definitelyNotInterestedInBuying == 0)}
                  >
                    <Icon name={"far:" + (formData.confirm == "no" ? "check-circle" : "circle")} size="xs" style={{ marginRight: "1rem" }} />
                    No
                  </button>
                </div>
              </React.Fragment>
            )
            setAfterForm("")

            switch (formData.confirm) {
              case "si":
                setFormTitle("Conferma")
                setFormDescription("Conferma la tua intenzione di acquisto.")
                setFormButton("Conferma la tua intenzione di acquisto")

                // Check if the user is subscribed, if not do not let finish step 3
                if (!subscribedUntil || isPast(parseISO(subscribedUntil))) {
                  form = null
                  setFormTitle("Associati")
                  setFormDescription("Per confermare la tua intenzione di acquisto devi prima associarti ad Ecoverso")
                  setAfterForm(
                    <div style={{ marginTop: "4rem" }}>
                      <p>
                        Per proseguire è necessario essere associati ad Ecoverso. Procedi cliccando sul bottone qua sotto e ritorna a questo gruppo per
                        confermare la tua scelta una volta associato/a.
                      </p>
                      <Link className="btn btn--white" to="/app/profile/subscription">
                        Clicca qui per associarti
                      </Link>
                    </div>
                  )
                } else {
                  // TODO: Manage multiple good/services bought in the same group
                  // for now fixed at 1
                  for (let rows = 0; rows < 1; rows++) {
                    const rowName = `products_${rows}`
                    let variantUserConfirmationCustomFieldsDef = {}

                    if (formData.values && formData.values[rowName]?.variant) {
                      variantUserConfirmationCustomFieldsDef = JSON.parse(
                        rdbGroupData.GoodOrServiceVariants?.find((g) => g.id == formData.values[rowName].variant)
                          .GoodOrService.GoodOrServiceType.userConfirmationCustomFieldsDef.replace(/\n/g, "")
                          .replace(/\r/g, "")
                          .replace(/\t/g, "")
                      )
                    }

                    form[rowName] = {
                      type: "object",
                      properties: {
                        variant: {
                          title: "La tua scelta",
                          type: "string",
                          fieldType: "select",
                          fieldProperties: {
                            placeholder: "Select...",
                            options: rdbGroupData.GoodsOrServices?.map((g) => {
                              return g.Variants?.length > 0
                                ? {
                                    label: g.GoodOrService.name,
                                    options: g.Variants?.map((v) => ({
                                      value: v.id,
                                      title: v.name,
                                    })),
                                  }
                                : null
                            }).filter((e) => e != null) || [{ value: "0", title: "Errore, contatta l'amminiatratore del sito" }],
                          },
                          disabled: true,
                        },
                        reseller: {
                          title: "Da quale fornitore",
                          type: "string",
                          fieldType: "select",
                          fieldProperties: {
                            placeholder: "Select...",
                            options: rdbGroupData.Resellers?.map((g) => ({
                              title: g.Organization.name,
                              value: g.Organization.id,
                            })) || [{ value: "0", title: "Errore, contatta l'amminiatratore del sito" }],
                          },
                          required: true,
                          errMessages: {
                            required: "E' necessario selezionare un fornitore",
                          },
                        },
                        ...variantUserConfirmationCustomFieldsDef,
                        //...custom_userConfirmationAuto,
                      },
                    }
                  }
                }

                // Output stringified JSON custom forms
                //console.log(JSON.stringify(custom_userConfirmationAuto))

                break
              case "no":
                setFormTitle("Grazie")
                setFormDescription(
                  "Ti ricordiamo che hai ancora " +
                    -differenceInCalendarDays(new Date(), choosingEndDate) +
                    " giorni per cambiare idea, oppure potrai registrati nuovamente in futuro."
                )
                setFormButton("Conferma la tua scelta")
                break
              default:
                setFormTitle("Conferma")
                setFormDescription("Confermi la tua intenzione di acquisto tramite Ecoverso?")
                form = null
            }

            if (userInGroupData.confirmationDate != null) {
              const savedConfirm = userInGroupData.definitelyNotInterestedInBuying ? "no" : "si"
              if (formData.confirm == null) formData.confirm = savedConfirm

              if (savedConfirm == formData.confirm && savedConfirm == "no") {
                form = null
              }
              setFormButton("Conferma la tua scelta")
            }
          } else {
            setFormTitle("Informazioni")
            setFormDescription(
              "Nello step 2 hai scelto di ricevere solo informazioni. Se hai cambiato idea e desideri acquistare, torna allo step 2 e modifica le tue intenzioni."
            )
            setPreForm("")
            setFormButton("")
            form = null
          }
        }
        break
      case 4:
        //---------------------------------------------------------------------------------------------------------------------------------------------------
        // Step UNICO
        if (rdbGroupData.GoodOrServiceVariants) {
          setIsFormVisible(isPast(confirmationStartDate))
          setIsFormDisabled(
            isFuture(confirmationStartDate) ||
              isPast(confirmationEndDate) ||
              (userInGroupData.confirmationDate && userInGroupData.definitelyNotInterestedInBuying == 0)
          )

          setPreForm(
            <React.Fragment>
              <div style={{ marginBottom: "2rem" }}>
                <button
                  className={"btn btn--center " + (formData.confirm == "si" ? "btn--white" : "btn--secondary")}
                  onClick={() => {
                    if (!isFormDisabled) setFormData({ ...formData, confirm: "si" })
                  }}
                  style={{ width: "40%", marginRight: "2rem" }}
                  disabled={isFormDisabled || (userInGroupData.confirmationDate && userInGroupData.definitelyNotInterestedInBuying == 0)}
                >
                  <Icon name={"far:" + (formData.confirm == "si" ? "check-circle" : "circle")} size="xs" style={{ marginRight: "1rem" }} />
                  Si
                </button>
                <button
                  className={"btn btn--center " + (formData.confirm == "no" ? "btn--white" : "btn--secondary")}
                  onClick={() => {
                    if (!isFormDisabled) setFormData({ ...formData, confirm: "no" })
                  }}
                  style={{ width: "40%" }}
                  disabled={isFormDisabled || (userInGroupData.confirmationDate && userInGroupData.definitelyNotInterestedInBuying == 0)}
                >
                  <Icon name={"far:" + (formData.confirm == "no" ? "check-circle" : "circle")} size="xs" style={{ marginRight: "1rem" }} />
                  No
                </button>
              </div>
            </React.Fragment>
          )
          setAfterForm("")

          switch (formData.confirm) {
            case "si":
              setFormTitle("Conferma")
              setFormDescription("Conferma la tua intenzione di acquisto.")
              setFormButton("Conferma la tua intenzione di acquisto")

              // Check if the user is subscribed, if not do not let finish step 3
              if (!subscribedUntil || isPast(parseISO(subscribedUntil))) {
                form = null
                setFormTitle("Associati")
                setFormDescription("Per confermare la tua intenzione di acquisto devi prima associarti ad Ecoverso")
                setAfterForm(
                  <div style={{ marginTop: "4rem" }}>
                    <p>
                      Per proseguire è necessario essere associati ad Ecoverso. Procedi cliccando sul bottone qua sotto e ritorna a questo gruppo per confermare
                      la tua scelta una volta associato/a.
                    </p>
                    <Link className="btn btn--white" to="/app/profile/subscription">
                      Clicca qui per associarti
                    </Link>
                  </div>
                )
              } else {
                if (userInGroupData?.User?.mobile == null || userInGroupData?.User?.mobile == "") {
                  form["mobile"] = {
                    type: "string",
                    ...mobile,
                    required: true,
                  }
                }
                if (!userInGroupData?.User?.state) {
                  form["state"] = {
                    ...state,
                    required: true,
                  }
                }

                // TODO: Manage multiple good/services bought in the same group
                // for now fixed at 1
                for (let rows = 0; rows < 1; rows++) {
                  const rowName = `products_${rows}`
                  let variantUserConfirmationCustomFieldsDef = {}

                  if (formData.values && formData.values[rowName]?.variant) {
                    variantUserConfirmationCustomFieldsDef = JSON.parse(
                      rdbGroupData.GoodOrServiceVariants?.find((g) => g.id == formData.values[rowName].variant)
                        .GoodOrService.GoodOrServiceType.userConfirmationCustomFieldsDef.replace(/\n/g, "")
                        .replace(/\r/g, "")
                        .replace(/\t/g, "")
                    )
                  }

                  form[rowName] = {
                    type: "object",
                    properties: {
                      variant: {
                        title: "La tua scelta",
                        type: "string",
                        fieldType: "select",
                        fieldProperties: {
                          placeholder: "Select...",
                          options: rdbGroupData.GoodsOrServices?.map((g) => {
                            return g.Variants?.length > 0
                              ? {
                                  label: g.GoodOrService.name,
                                  options: g.Variants?.map((v) => ({
                                    value: v.id,
                                    title: v.name,
                                  })),
                                }
                              : null
                          }).filter((e) => e != null) || [{ value: "0", title: "Errore, contatta l'amminiatratore del sito" }],
                        },
                        required: true,
                        errMessages: {
                          required: "E' necessario effettuare una scelta",
                        },
                      },
                      reseller: {
                        title: "Da quale fornitore",
                        type: "string",
                        fieldType: "select",
                        fieldProperties: {
                          placeholder: "Select...",
                          options: rdbGroupData.Resellers?.map((g) => ({
                            title: g.Organization.name,
                            value: g.Organization.id,
                          })) || [{ value: "0", title: "Errore, contatta l'amminiatratore del sito" }],
                        },
                        required: true,
                        errMessages: {
                          required: "E' necessario selezionare un fornitore",
                        },
                      },
                      ...variantUserConfirmationCustomFieldsDef,
                      //...custom_userConfirmationAuto,
                    },
                  }
                }
              }

              // Output stringified JSON custom forms
              //console.log(JSON.stringify(custom_userConfirmationAuto))

              break
            case "no":
              setFormTitle("Grazie")
              setFormDescription(
                "Ti ricordiamo che hai ancora " +
                  -differenceInCalendarDays(new Date(), choosingEndDate) +
                  " giorni per cambiare idea, oppure potrai registrati nuovamente in futuro."
              )
              setFormButton("Conferma la tua scelta")
              break
            default:
              setFormTitle("Conferma")
              setFormDescription("Confermi la tua intenzione di acquisto tramite Ecoverso?")
              form = null
          }

          if (userInGroupData.confirmationDate != null) {
            const savedConfirm = userInGroupData.definitelyNotInterestedInBuying ? "no" : "si"
            if (formData.confirm == null) formData.confirm = savedConfirm

            if (savedConfirm == formData.confirm && savedConfirm == "no") {
              form = null
            }
            setFormButton("Conferma la tua scelta")
          }
        }
        break
    }

    if (form != null) {
      form["confirmWhatRead"] = {
        type: "boolean",
        title: "Ho letto e compreso le istruzioni di questa pagina",
        className: "mb-none",
        required: true,
        errMessages: {
          required: "Per proseguire è necessario leggere e confermare di aver compreso le istruzioni di questa pagina",
        },
      }
    }

    //console.log(form, localCurrentStep, rdbGroupData)
    setForm(form)
  }, [localCurrentStep, userInGroupData, formData, rdbGroupData])

  //---------------------------------------------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------------------------------------------
  // Submit handling

  const onSubmit = async (values: any, setSubmitting) => {
    setIsLoading(true)
    switch (localCurrentStep) {
      case 1:
        //---------------------------------------------------------------------------------------------------------------------------------------------------
        // Step 1
        updateUserInGroup({
          variables: { updateUser_in_buying_groupInput: { id: userInGroupData.id, participationDate: "set" } },
        })
        // Check if we also need to update mobile or stte
        if (values.mobile || values.state) {
          let updateUserValues = {}
          if (values.mobile) updateUserValues["mobile"] = values.mobile
          if (values.state) updateUserValues["state"] = values.state
          await updateUserData({ variables: { ...updateUserValues, id: userId } })

          // Send event to GTM
          window?.dataLayer?.push({
            event: "group-step-completed",
            step: "1",
            groupUID: rdbGroupData.uidCMS,
            groupName: rdbGroupData.name,
            groupParticipationScope: "informazioni",
            groupDeclination: rdbGroupData.declination,
          })
        }

        break
      case 2:
        //---------------------------------------------------------------------------------------------------------------------------------------------------
        // Step 2

        let foundChosedVariantIds = []

        if (formData.intention == "acquisto") {
          // TODO: Manage multiple chosed variants bought in the same group

          // for now fixed at 1 -
          for (let rows = 0; rows < 1; rows++) {
            const rowName = `products_${rows}`

            // Define chosedVariant from form values
            const chosedVariant = {
              idBuyingGroup: rdbGroupData.id,
              idUser: userId,
              idVariant: values[rowName].variant,
              quantity: 1,
              dateChosed: "set",
              userChoosingCustomValues: JSON.stringify(_.omit(values[rowName], ["variant"])).replace(/\\n/g, "\\\\n"),
              dateConfirmed: null,
              dateCanceled: null,
              cancellationPhase: null,
            }
            console.log("chosedVariant: ", chosedVariant)

            // Check if chosedVariant already exists (precedently saved)
            let foundChosedVariantId = null
            if (Array.isArray(userInGroupData.ChosedVariants)) {
              userInGroupData.ChosedVariants.forEach((c) => {
                if (c.idBuyingGroup == chosedVariant.idBuyingGroup && c.idUser == chosedVariant.idUser && c.idVariant == chosedVariant.idVariant) {
                  foundChosedVariantId = c.id
                  foundChosedVariantIds.push(c.id)
                }
              })
            }

            // If exist update else create
            if (foundChosedVariantId) {
              await updateUserChosedVariant({ variables: { updateChosed_variantInput: { ...chosedVariant, id: foundChosedVariantId } } })
            } else {
              await createUserChosedVariant({ variables: { createChosed_variantInput: { ...chosedVariant } } })
            }
          }
        }

        // Now one of 2:
        //  + cancel eventual old chosedvariants that are no longer present in foundChosedVariantId[]
        //  + Vogliamo solo informazioni, verifica se ci sono chosed variants e cancellale (foundChosedVariantId is empty)
        if (Array.isArray(userInGroupData.ChosedVariants)) {
          for (let i = 0; i < userInGroupData.ChosedVariants.length; i++) {
            const c = userInGroupData.ChosedVariants[i]
            if (!foundChosedVariantIds.includes(c.id) && !c.dateCanceled) {
              await updateUserChosedVariant({ variables: { updateChosed_variantInput: { id: c.id, dateCanceled: "set", cancellationPhase: "choosing" } } })
            }
          }
        }

        // Save userInGroup Data
        await updateUserInGroup({
          variables: {
            updateUser_in_buying_groupInput: {
              id: userInGroupData.id,
              choosingDate: "set",
              notInterestInBuying: formData.intention == "informazioni" ? 1 : 0,
              confirmationDate: null,
              definitelyNotInterestedInBuying: null,
            },
          },
        })

        // Send event to GTM
        window?.dataLayer?.push({
          event: "group-step-completed",
          step: "2",
          groupUID: rdbGroupData.uidCMS,
          groupName: rdbGroupData.name,
          groupParticipationScope: formData.intention,
          groupDeclination: rdbGroupData.declination,
        })

        break
      case 3:
        //---------------------------------------------------------------------------------------------------------------------------------------------------
        // Step 3

        if (formData.confirm == "si") {
          // TODO: Manage multiple chosed variants bought in the same group

          // for now fixed at 1 -
          for (let rows = 0; rows < 1; rows++) {
            const rowName = `products_${rows}`

            // Define chosedVariant from form values
            const confirmedVariant = {
              idBuyingGroup: rdbGroupData.id,
              idUser: userId,
              idVariant: values[rowName].variant,
              idReseller: values[rowName].reseller,
              dateConfirmed: "set",
              userConfirmationCustomValues: JSON.stringify(_.omit(values[rowName], ["variant", "reseller"])).replace(/\\n/g, "\\\\n"),
              dateCanceled: null,
              cancellationPhase: null,
            }
            console.log("chosedVariant: ", confirmedVariant)

            // Check if chosedVariant already exists (precedently saved)
            // (it should always exist because we are at step 3)
            let foundChosedVariantId = null
            if (Array.isArray(userInGroupData.ChosedVariants)) {
              userInGroupData.ChosedVariants.forEach((c) => {
                if (c.idBuyingGroup == confirmedVariant.idBuyingGroup && c.idUser == confirmedVariant.idUser && c.idVariant == confirmedVariant.idVariant) {
                  foundChosedVariantId = c.id
                }
              })
            }

            // If exist update else create
            if (foundChosedVariantId) {
              await updateUserChosedVariant({ variables: { updateChosed_variantInput: { ...confirmedVariant, id: foundChosedVariantId } } })
            } else {
              console.log("ChosedVariant not found while confirming, this is a problem!")
              //await createUserChosedVariant({ variables: { createChosed_variantInput: { ...confirmedVariant } } })
            }
          }
        } else {
          // Non confermiamo l'acquisto, verifica se ci sono chosed variants ed cancellale
          if (Array.isArray(userInGroupData.ChosedVariants)) {
            for (let i = 0; i < userInGroupData.ChosedVariants.length; i++) {
              const c = userInGroupData.ChosedVariants[i]
              if (!c.dateCanceled) {
                await updateUserChosedVariant({
                  variables: { updateChosed_variantInput: { id: c.id, dateCanceled: "set", cancellationPhase: "confirmation" } },
                })
              }
            }
          }
        }

        // Save userInGroup Data
        await updateUserInGroup({
          variables: {
            updateUser_in_buying_groupInput: {
              id: userInGroupData.id,
              confirmationDate: "set",
              definitelyNotInterestedInBuying: formData.confirm == "no" ? 1 : 0,
            },
          },
        })

        // Send event to GTM
        window?.dataLayer?.push({
          event: "group-step-completed",
          step: "3",
          groupUID: rdbGroupData.uidCMS,
          groupName: rdbGroupData.name,
          groupParticipationScope: formData.confirm == "no" ? "informazioni" : "acquisto",
          groupDeclination: rdbGroupData.declination,
        })

        break
      case 4:
        //---------------------------------------------------------------------------------------------------------------------------------------------------
        // Step UNICO

        if (formData.confirm == "si") {
          // TODO: Manage multiple chosed variants bought in the same group

          // for now fixed at 1 -
          for (let rows = 0; rows < 1; rows++) {
            const rowName = `products_${rows}`

            // Define chosedVariant from form values
            const confirmedVariant = {
              idBuyingGroup: rdbGroupData.id,
              idUser: userId,
              idVariant: values[rowName].variant,
              idReseller: values[rowName].reseller,
              dateConfirmed: "set",
              userConfirmationCustomValues: JSON.stringify(_.omit(values[rowName], ["variant", "reseller"])).replace(/\\n/g, "\\\\n"),
              dateCanceled: null,
              cancellationPhase: null,
            }
            console.log("chosedVariant: ", confirmedVariant)

            // Check if chosedVariant already exists (precedently saved)
            // (it should always exist because we are at step 3)
            let foundChosedVariantId = null
            if (Array.isArray(userInGroupData.ChosedVariants)) {
              userInGroupData.ChosedVariants.forEach((c) => {
                if (c.idBuyingGroup == confirmedVariant.idBuyingGroup && c.idUser == confirmedVariant.idUser && c.idVariant == confirmedVariant.idVariant) {
                  foundChosedVariantId = c.id
                }
              })
            }

            // If exist update else create
            if (foundChosedVariantId) {
              await updateUserChosedVariant({ variables: { updateChosed_variantInput: { ...confirmedVariant, id: foundChosedVariantId } } })
            } else {
              await createUserChosedVariant({ variables: { createChosed_variantInput: { ...confirmedVariant } } })
            }
          }
        } else {
          // Non confermiamo l'acquisto, verifica se ci sono chosed variants ed cancellale
          if (Array.isArray(userInGroupData.ChosedVariants)) {
            for (let i = 0; i < userInGroupData.ChosedVariants.length; i++) {
              const c = userInGroupData.ChosedVariants[i]
              if (!c.dateCanceled) {
                await updateUserChosedVariant({
                  variables: { updateChosed_variantInput: { id: c.id, dateCanceled: "set", cancellationPhase: "confirmation" } },
                })
              }
            }
          }
        }

        // Check if we also need to update mobile or stte
        if (values.mobile || values.state) {
          let updateUserValues = {}
          if (values.mobile) updateUserValues["mobile"] = values.mobile
          if (values.state) updateUserValues["state"] = values.state
          await updateUserData({ variables: { ...updateUserValues, id: userId } })
        }

        // Save userInGroup Data
        await updateUserInGroup({
          variables: {
            updateUser_in_buying_groupInput: {
              id: userInGroupData.id,
              participationDate: "set",
              choosingDate: "set",
              notInterestInBuying: 0,
              confirmationDate: "set",
              definitelyNotInterestedInBuying: formData.confirm == "no" ? 1 : 0,
            },
          },
        })

        // Send event to GTM
        window?.dataLayer?.push({
          event: "group-step-completed",
          step: "3",
          groupUID: rdbGroupData.uidCMS,
          groupName: rdbGroupData.name,
          groupParticipationScope: formData.confirm == "no" ? "informazioni" : "acquisto",
          groupDeclination: rdbGroupData.declination,
        })

        break
    }
    setSubmitting(false)
    setIsLoading(false)
  }

  const onChange = (values) => {
    let newData = { ...formData, values }
    setFormData(newData)
    //console.log(newData)
  }

  //---------------------------------------------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------------------------------------------
  // Rendering

  return (
    <React.Fragment>
      {reachedStep != currentStep ? (
        <InContentTopBar
          currentStep={currentStep}
          rdbGroupData={rdbGroupData}
          userInGroupData={userInGroupData}
          onRegister={() => {}}
          setCurrentStep={(step) => {
            scrollToTop()
            setTimeout(() => setCurrentStep(step), 300)
          }}
          scrollToForm={() => {}}
        />
      ) : null}
      <a id="form"></a>
      {isFormVisible ? (
        <div className="row row--full row__background--arrow row__background--blu-elettrico">
          <div className="row row--center row--gap-big">
            <div className="col--3">
              <h2 className="heading heading-title">{formTitle}</h2>
              <p className="heading-subtitle">{formDescription}</p>
            </div>
            <div className="col--1"></div>
            <div className="col--7">
              {preForm}
              {form != null ? (
                <FormGenerator
                  className="btn--fullwidth"
                  properties={form}
                  hideCancelButton
                  submitButtonText={formButton}
                  onSubmit={onSubmit}
                  onChange={onChange}
                  values={formData.values || {}}
                  disabled={isFormDisabled}
                  //isDemo
                  //debug
                />
              ) : null}
              {afterForm}
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default Forms
