import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { StepProps } from "../../Layout/Components"
import * as actions from "../../store/actions"

import { differenceInCalendarDays, isAfter, isFuture, isPast } from "date-fns"
import { FormatDate, Link, useI18next } from "../../i18n"
import { buying_group, user_in_buying_group } from "../../API"
import Steps from "../../Layout/Components/Steps"
import { TFunction } from "i18next"

import { buyingGroupData } from "../crmWpBuyingGroup"
import { parseISO } from "date-fns/esm"

type groupStepsProps = {
  currentStep: number
  rdbGroupData: buyingGroupData
  userInGroupData: user_in_buying_group | null
  onRegister: Function
  setCurrentStep: Function
}

type groupDatesType = {
  pubblicationStartDate?: Date
  pubblicationEndDate?: Date
  registrationStartDate?: Date
  registrationEndDate?: Date
  participationStartDate?: Date
  participationEndDate?: Date
  choosingStartDate?: Date
  choosingEndDate?: Date
  confirmationStartDate?: Date
  confirmationEndDate?: Date
  resellerFlowStartDate?: Date
  resellerFlowEndDate?: Date
}

//---------------------------------------------------------------------------------------------------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------------------------------
// Step per NON registrati al gruppo
const getUnsubscribedStep = (
  stepNumber: number,
  declination: string,
  { registrationStartDate, registrationEndDate, confirmationStartDate, confirmationEndDate }: groupDatesType,
  onRegister: Function,
  isLoggedIn: boolean,
  dispatch: Function
) => {
  let step: StepProps

  switch (stepNumber) {
    case 1:
      //---------------------------------------------------------------------------------------------------------------------------------------------------
      // Step 1

      step = { title: "Registrati", borderColor: "green", active: true, icon: "enter" }
      if (!registrationStartDate) {
        step = {
          ...step,
          borderColor: "orange",
          icon: "time",
          children: (
            <span>
              Presto potrai <b>registrarti gratuitamente</b> e <b>senza impegno</b> a{" "}
              {{ Servizio: "questo servizio", Auto: "questo gruppo d'acquisto", Convenzione: "questa convenzione" }[declination]}
            </span>
          ),
          unclickable: true,
        }
      } else if (isFuture(registrationStartDate)) {
        step = {
          ...step,
          borderColor: "orange",
          icon: "time",
          children: (
            <span>
              Fra {differenceInCalendarDays(registrationStartDate, new Date())} giorni potrai <b>registrarti gratis</b> e <b>senza impegno</b> a{" "}
              {declination == "Servizio" ? "servizio" : "gruppo"}
              {{ Servizio: "questo servizio", Auto: "questo gruppo", Convenzione: "questa convenzione" }[declination]}
            </span>
          ),
          unclickable: true,
        }
      } else if (registrationEndDate == null) {
        step = isLoggedIn
          ? {
              ...step,
              children: (
                <span>
                  <b>Registrati gratuitamente</b> e <b>senza impegno</b> a{" "}
                  {{ Servizio: "questo servizio", Auto: "questo gruppo d'acquisto", Convenzione: "questa convenzione" }[declination]}
                </span>
              ),
              onClick: () => onRegister(),
            }
          : {
              ...step,
              children: (
                <span>
                  <b>Accedi</b> al sito per <b>registrarti gratuitamente</b> e <b>senza impegno</b> a{" "}
                  {{ Servizio: "questo servizio", Auto: "questo gruppo d'acquisto", Convenzione: "questa convenzione" }[declination]}
                </span>
              ),
              onClick: () => {
                dispatch(actions.setLoginModalVisibility(true))
              },
            }
      } else if (isFuture(registrationEndDate)) {
        step = {
          ...step,
          children: (
            <React.Fragment>
              {/* <div style={{ float: "left", fontSize: "2rem", height: "5rem", lineHeight: "2rem", marginRight: "0.6rem" }}>
              {differenceInCalendarDays(new Date(), registrationEndDate)}
            </div> */}
              <div>
                Hai solo <b>{-differenceInCalendarDays(new Date(), registrationEndDate)} giorni</b> per <b>registrarti gratis</b> e <b>senza impegno</b> a{" "}
                {{ Servizio: "questo servizio", Auto: "questo gruppo", Convenzione: "questa convenzione" }[declination]}
              </div>
            </React.Fragment>
          ),
          onClick: () => onRegister(),
        }
      } else if (isPast(registrationEndDate)) {
        step = {
          ...step,
          borderColor: "red",
          icon: "do-not-enter",
          children: (
            <span>
              Ci dispiace, le registrazioni a{" "}
              {{ Servizio: "questo servizio", Auto: "questo gruppo d'acquisto", Convenzione: "questa convenzione" }[declination]} sono chiuse
            </span>
          ),
          unclickable: true,
        }
      }
      break
    case 2:
      //---------------------------------------------------------------------------------------------------------------------------------------------------
      // Step 2

      switch (declination) {
        case "Servizio":
          step = {
            title: "Scopri",
            borderColor: "blue",
            active: false,
            icon: "cars",
            children: (
              <span>
                Scopri gli <b>sconti</b> e i <b>fornitori</b> di questo servizio
              </span>
            ),
            unclickable: true,
          }
          break
        case "Convenzione":
          step = {
            title: "Scopri",
            borderColor: "blue",
            active: false,
            icon: "cars",
            children: (
              <span>
                Scopri gli <b>sconti</b> e i <b>fornitori</b> di questa convenzione
              </span>
            ),
            unclickable: true,
          }
          break
        case "Auto":
        default:
          step = {
            title: "Scopri",
            borderColor: "blue",
            active: false,
            icon: "cars",
            children: (
              <span>
                Scopri gli <b>sconti</b> e i <b>fornitori</b> di questo gruppo d'acquisto
              </span>
            ),
            unclickable: true,
          }
      }

      break
    case 3:
      //---------------------------------------------------------------------------------------------------------------------------------------------------
      // Step 3

      switch (declination) {
        case "Servizio":
          step = {
            title: "Risparmia",
            borderColor: "green",
            active: false,
            icon: "euro",
            children: (
              <span>
                <b>Acquista</b> il tuo servizio{" "}
                {confirmationStartDate ? (
                  <span>
                    dal <FormatDate date={confirmationStartDate} format="dd/MM" />{" "}
                  </span>
                ) : null}
                {confirmationEndDate ? (
                  <span>
                    al <FormatDate date={confirmationEndDate} format="dd/MM" />{" "}
                  </span>
                ) : null}
                <b>risparmiando</b>
              </span>
            ),
            unclickable: true,
          }
          break
        case "Convenzione":
          step = {
            title: "Risparmia",
            borderColor: "green",
            active: false,
            icon: "euro",
            children: (
              <span>
                <b>Aderisci</b> alla convenzione{" "}
                {confirmationStartDate ? (
                  <span>
                    dal <FormatDate date={confirmationStartDate} format="dd/MM" />{" "}
                  </span>
                ) : null}
                {confirmationEndDate ? (
                  <span>
                    al <FormatDate date={confirmationEndDate} format="dd/MM" />{" "}
                  </span>
                ) : null}
              </span>
            ),
            unclickable: true,
          }
          break
        case "Auto":
        default:
          step = {
            title: "Risparmia",
            borderColor: "green",
            active: false,
            icon: "euro",
            children: (
              <span>
                <b>Acquista</b> la tua nuova auto{" "}
                {confirmationStartDate ? (
                  <span>
                    dal <FormatDate date={confirmationStartDate} format="dd/MM" />{" "}
                  </span>
                ) : null}
                {confirmationEndDate ? (
                  <span>
                    al <FormatDate date={confirmationEndDate} format="dd/MM" />{" "}
                  </span>
                ) : null}
                <b>risparmiando in media ulteriori 1000€</b>
              </span>
            ),
            unclickable: true,
          }
      }

      break
  }

  return step
}

//---------------------------------------------------------------------------------------------------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------------------------------
// Step per NON registrati al gruppo
const getSubscribedStep = (
  stepNumber: number,
  declination: string,
  { participationStartDate, participationEndDate, choosingStartDate, choosingEndDate, confirmationStartDate, confirmationEndDate }: groupDatesType,
  userInGroupData: user_in_buying_group,
  currentStep: number,
  setCurrentStep: Function,
  t: TFunction
) => {
  let step: StepProps
  let reachedStep = userInGroupData.confirmationDate ? 4 : userInGroupData.choosingDate ? 3 : userInGroupData.participationDate ? 2 : 1

  // reachedStep = 4
  // participationStartDate = new Date("2021-03-10")
  // participationEndDate = new Date("2021-03-16")
  // choosingStartDate = new Date("2021-03-12")
  // choosingEndDate = new Date("2021-03-17")
  // confirmationStartDate = new Date("2021-03-14")
  // confirmationEndDate = new Date("2021-03-19")

  switch (stepNumber) {
    case 1:
      //---------------------------------------------------------------------------------------------------------------------------------------------------
      // Step 1

      step = {
        title: "1. Partecipa",
        borderColor: reachedStep > 1 ? "green" : isPast(participationEndDate) ? "red" : "orange",
        icon:
          reachedStep > 1
            ? "check"
            : isFuture(participationStartDate) || !participationStartDate
            ? "time"
            : isPast(participationEndDate)
            ? "do-not-enter"
            : "edit",
        children:
          reachedStep > 1 ? (
            <span>Stai partecipando a questo gruppo d'acquisto</span>
          ) : !participationStartDate ? (
            <span>
              <b>Coming Soon</b> - presto pubblicheremo date e istruzioni di questo step
            </span>
          ) : isFuture(participationStartDate) ? (
            <span
              dangerouslySetInnerHTML={{
                __html: t("group_participation_starts_in_x_days_interval", {
                  postProcess: "interval",
                  count: -differenceInCalendarDays(new Date(), participationStartDate),
                }),
              }}
            ></span>
          ) : isPast(participationEndDate) ? (
            <span>Ci dispiace, la partecipazione a questo gruppo d'acquisto è chiusa</span>
          ) : !participationEndDate ? (
            isFuture(choosingStartDate) ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: t("group_participation_but_chosing_starts_in_x_days", {
                    postProcess: "interval",
                    count: -differenceInCalendarDays(new Date(), choosingStartDate),
                  }),
                }}
              ></span>
            ) : isPast(choosingStartDate) ? (
              <span>Compila il form a fondo pagina per partecipare al gruppo d'acquisto, il prossimo step è già aperto</span>
            ) : (
              <span>Compila il form a fondo pagina per partecipare al gruppo d'acquisto</span>
            )
          ) : // Il gruppo è aperto, check anche sulle date dello step successivo.
          isFuture(choosingStartDate) && isAfter(participationEndDate, choosingStartDate) ? (
            <span
              dangerouslySetInnerHTML={{
                __html: t("group_participation_but_chosing_starts_in_x_days", {
                  postProcess: "interval",
                  count: -differenceInCalendarDays(new Date(), choosingStartDate),
                }),
              }}
            ></span>
          ) : isPast(choosingStartDate) ? (
            <span
              dangerouslySetInnerHTML={{
                __html: t("group_participation_with_chosing_open_ends_in_x_days", {
                  postProcess: "interval",
                  count: -differenceInCalendarDays(new Date(), participationEndDate),
                }),
              }}
            ></span>
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: t("group_participation_ends_in_x_days_interval", {
                  postProcess: "interval",
                  count: -differenceInCalendarDays(new Date(), participationEndDate),
                }),
              }}
            ></span>
          ),
        active: currentStep == 1,
        unclickable: currentStep == 1,
        disabled: reachedStep <= 1 && (isFuture(participationStartDate) || !participationStartDate),
        onClick: () => {
          setCurrentStep(1)
        },
      }

      break
    case 2:
      //---------------------------------------------------------------------------------------------------------------------------------------------------
      // Step 2

      step = {
        title: "2. Scegli",
        borderColor: reachedStep > 2 ? "green" : isPast(choosingEndDate) ? "red" : reachedStep < 2 ? "blue" : "orange",
        icon: reachedStep > 2 ? "check" : isFuture(choosingStartDate) || !choosingStartDate ? "time" : isPast(choosingEndDate) ? "do-not-enter" : "cars",
        children:
          reachedStep > 2 ? (
            <span>Hai effettuato la tua scelta</span>
          ) : !choosingStartDate ? (
            <span>
              <b>Coming Soon</b> - presto pubblicheremo date e istruzioni di questo step
            </span>
          ) : isFuture(choosingStartDate) ? (
            <span
              dangerouslySetInnerHTML={{
                __html: t("group_choosing_starts_in_x_days_interval", {
                  postProcess: "interval",
                  count: -differenceInCalendarDays(new Date(), choosingStartDate),
                }),
              }}
            ></span>
          ) : isPast(choosingEndDate) ? (
            <span>Ci dispiace, questo step è chiuso</span>
          ) : !choosingEndDate ? (
            isFuture(confirmationStartDate) ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: t("group_participation_but_chosing_starts_in_x_days", {
                    postProcess: "interval",
                    count: -differenceInCalendarDays(new Date(), choosingStartDate),
                  }),
                }}
              ></span>
            ) : isPast(confirmationStartDate) ? (
              <span>Compila il form a fondo pagina, il prossimo step è già aperto</span>
            ) : (
              <span>Compila il form a fondo pagina</span>
            )
          ) : // Il gruppo è aperto, check anche sulle date dello step successivo.
          isFuture(confirmationStartDate) && isAfter(choosingEndDate, confirmationStartDate) ? (
            <span
              dangerouslySetInnerHTML={{
                __html: t("group_choosing_but_confirmation_starts_in_x_days", {
                  postProcess: "interval",
                  count: -differenceInCalendarDays(new Date(), confirmationStartDate),
                }),
              }}
            ></span>
          ) : isPast(confirmationStartDate) ? (
            <span
              dangerouslySetInnerHTML={{
                __html: t("group_choosing_with_confirmation_open_ends_in_x_days", {
                  postProcess: "interval",
                  count: -differenceInCalendarDays(new Date(), choosingEndDate),
                }),
              }}
            ></span>
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: t("group_choosing_ends_in_x_days_interval", {
                  postProcess: "interval",
                  count: -differenceInCalendarDays(new Date(), choosingEndDate),
                }),
              }}
            ></span>
          ),

        active: currentStep == 2,
        unclickable: currentStep == 2,
        disabled: reachedStep <= 2 && (isFuture(choosingStartDate) || !choosingStartDate),
        onClick: () => {
          setCurrentStep(2)
        },
      }

      break
    case 3:
      //---------------------------------------------------------------------------------------------------------------------------------------------------
      // Step 3

      step = {
        title: "3. Conferma",
        borderColor: reachedStep > 3 ? "green" : isPast(confirmationEndDate) ? "red" : reachedStep < 3 ? "blue" : "orange",
        icon:
          reachedStep > 3
            ? "check"
            : isFuture(confirmationStartDate) || !confirmationStartDate
            ? "time"
            : isPast(confirmationEndDate)
            ? "do-not-enter"
            : "euro",
        children:
          reachedStep > 3 ? (
            <span>Hai confermato la tua scelta</span>
          ) : !confirmationStartDate ? (
            <span>
              <b>Coming Soon</b> - presto pubblicheremo date e istruzioni di questo step
            </span>
          ) : isFuture(confirmationStartDate) ? (
            <span
              dangerouslySetInnerHTML={{
                __html: t("group_confirmation_starts_in_x_days_interval", {
                  postProcess: "interval",
                  count: -differenceInCalendarDays(new Date(), confirmationStartDate),
                }),
              }}
            ></span>
          ) : isPast(confirmationEndDate) ? (
            <span>Ci dispiace, questo step è chiuso</span>
          ) : !confirmationEndDate ? (
            <span>Compila il form a fondo pagina per confermare</span>
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: t("group_confirmation_ends_in_x_days_interval", {
                  postProcess: "interval",
                  count: -differenceInCalendarDays(new Date(), confirmationEndDate),
                }),
              }}
            ></span>
          ),
        active: currentStep == 3,
        unclickable: currentStep == 3,
        disabled: reachedStep <= 3 && (isFuture(confirmationStartDate) || !confirmationStartDate),
        onClick: () => {
          setCurrentStep(3)
        },
      }

      break
  }

  return step
}

//---------------------------------------------------------------------------------------------------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------------------------------
// Build the steps

const GroupSteps: React.FC<groupStepsProps> = ({ currentStep, rdbGroupData, userInGroupData, onRegister, setCurrentStep }) => {
  const { t } = useI18next()

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
  const dispatch = useDispatch()

  const registrationStartDate = rdbGroupData.registrationStartDate != null ? parseISO(rdbGroupData.registrationStartDate) : null
  const registrationEndDate = rdbGroupData.registrationEndDate != null ? parseISO(rdbGroupData.registrationEndDate) : null
  const participationStartDate = rdbGroupData.participationStartDate != null ? parseISO(rdbGroupData.participationStartDate) : null
  const participationEndDate = rdbGroupData.participationEndDate != null ? parseISO(rdbGroupData.participationEndDate) : null
  const choosingStartDate = rdbGroupData.choosingStartDate != null ? parseISO(rdbGroupData.choosingStartDate) : null
  const choosingEndDate = rdbGroupData.choosingEndDate != null ? parseISO(rdbGroupData.choosingEndDate) : null
  const confirmationStartDate = rdbGroupData.confirmationStartDate != null ? parseISO(rdbGroupData.confirmationStartDate) : null
  const confirmationEndDate = rdbGroupData.confirmationEndDate != null ? parseISO(rdbGroupData.confirmationEndDate) : null

  let stepsData: StepProps[] = []
  let skipSteps = false

  if (userInGroupData == null) {
    stepsData.push(
      getUnsubscribedStep(
        1,
        rdbGroupData.declination,
        { registrationStartDate, registrationEndDate, confirmationStartDate, confirmationEndDate },
        onRegister,
        isLoggedIn,
        dispatch
      )
    )
    stepsData.push(
      getUnsubscribedStep(
        2,
        rdbGroupData.declination,
        { registrationStartDate, registrationEndDate, confirmationStartDate, confirmationEndDate },
        onRegister,
        isLoggedIn,
        dispatch
      )
    )
    stepsData.push(
      getUnsubscribedStep(
        3,
        rdbGroupData.declination,
        { registrationStartDate, registrationEndDate, confirmationStartDate, confirmationEndDate },
        onRegister,
        isLoggedIn,
        dispatch
      )
    )
  } else {
    // Non visualizzare gli step per alcune tipologie di gruppo
    if (rdbGroupData.uniteChooseAndConfirm) skipSteps = true

    stepsData.push(
      getSubscribedStep(
        1,
        rdbGroupData.declination,
        { participationStartDate, participationEndDate, choosingStartDate, choosingEndDate, confirmationStartDate, confirmationEndDate },
        userInGroupData,
        currentStep,
        setCurrentStep,
        t
      )
    )
    stepsData.push(
      getSubscribedStep(
        2,
        rdbGroupData.declination,
        { participationStartDate, participationEndDate, choosingStartDate, choosingEndDate, confirmationStartDate, confirmationEndDate },
        userInGroupData,
        currentStep,
        setCurrentStep,
        t
      )
    )
    stepsData.push(
      getSubscribedStep(
        3,
        rdbGroupData.declination,
        { participationStartDate, participationEndDate, choosingStartDate, choosingEndDate, confirmationStartDate, confirmationEndDate },
        userInGroupData,
        currentStep,
        setCurrentStep,
        t
      )
    )
  }

  return !skipSteps ? <Steps steps={stepsData} /> : null
}

export default GroupSteps
