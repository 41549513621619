import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { FormatDate, Link, useI18next } from "../../i18n"
import * as actions from "../../store/actions"
import { buying_group, user_in_buying_group } from "../../API"
import { Icon, Title, Breadcrumbs, Image, ImageData } from "../../Layout/Components"
import { differenceInCalendarDays, isFuture, isPast, parseISO } from "date-fns"

import { buyingGroupData } from "../crmWpBuyingGroup"

type InContentTopBarProps = {
  currentStep: number
  rdbGroupData: buyingGroupData
  userInGroupData: user_in_buying_group | null
  onRegister: Function
  setCurrentStep: Function
  scrollToForm: Function
}

const InContentTopBar: React.FC<InContentTopBarProps> = ({ currentStep, rdbGroupData, userInGroupData, onRegister, setCurrentStep, scrollToForm }) => {
  const dispatch = useDispatch()
  const { t } = useI18next()

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
  const userFirstName = useSelector((state) => state.auth.payload?.userData?.firstName)

  let registrationStartDate = rdbGroupData.registrationStartDate != null ? parseISO(rdbGroupData.registrationStartDate) : null
  let registrationEndDate = rdbGroupData.registrationEndDate != null ? parseISO(rdbGroupData.registrationEndDate) : null
  let participationStartDate = rdbGroupData.participationStartDate != null ? parseISO(rdbGroupData.participationStartDate) : null
  let participationEndDate = rdbGroupData.participationEndDate != null ? parseISO(rdbGroupData.participationEndDate) : null
  let choosingStartDate = rdbGroupData.choosingStartDate != null ? parseISO(rdbGroupData.choosingStartDate) : null
  let choosingEndDate = rdbGroupData.choosingEndDate != null ? parseISO(rdbGroupData.choosingEndDate) : null
  let confirmationStartDate = rdbGroupData.confirmationStartDate != null ? parseISO(rdbGroupData.confirmationStartDate) : null
  let confirmationEndDate = rdbGroupData.confirmationEndDate != null ? parseISO(rdbGroupData.confirmationEndDate) : null

  const declination = rdbGroupData.declination

  let barHeader = null
  let borderColor = "blue"
  if (userInGroupData == null) {
    //------------------------------------------------------------------------------------------------------------------------------
    // User NOT in group + Registrations open > Invite user to register
    if (isPast(registrationStartDate) && (isFuture(registrationEndDate) || registrationEndDate == null)) {
      barHeader = isLoggedIn ? (
        <React.Fragment>
          Ciao {userFirstName}, entra anche tu in{" "}
          {{ Servizio: "questo servizio", Auto: "questo gruppo d'acquisto", Convenzione: "questa convenzione" }[declination]} gratuitamente e senza impegno
          <div className="btn btn--primary" onClick={() => onRegister()}>
            {t("Entra")} <Icon name="fal:sign-in-alt" size="s" style={{ marginLeft: "1rem" }} />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          Per entrare in {{ Servizio: "questo servizio", Auto: "questo gruppo d'acquisto", Convenzione: "questa convenzione" }[declination]} accedi al sito
          <div className="btn btn--primary" onClick={() => dispatch(actions.setLoginModalVisibility(true))}>
            {t("Accedi")} <Icon name="user" size="s" strokeWidth={2} style={{ marginLeft: "1rem" }} />
          </div>
        </React.Fragment>
      )
    }
  } else {
    let reachedStep = userInGroupData.confirmationDate ? 4 : userInGroupData.choosingDate ? 3 : userInGroupData.participationDate ? 2 : 1

    // reachedStep = 4
    // participationStartDate = new Date("2021-03-10")
    // participationEndDate = new Date("2021-03-16")
    // choosingStartDate = new Date("2021-03-12")
    // choosingEndDate = new Date("2021-03-17")
    // confirmationStartDate = new Date("2021-03-14")
    // confirmationEndDate = new Date("2021-03-19")

    if (rdbGroupData.uniteChooseAndConfirm) {
      if (reachedStep > 1) {
        barHeader = <React.Fragment>Hai confermato la tua scelta</React.Fragment>
        borderColor = "green"
        if (userInGroupData.definitelyNotInterestedInBuying) {
          borderColor = "orange"
          barHeader = (
            <React.Fragment>
              Hai scelto di non acquistare, se cambi idea torna al form
              <div className="btn btn--primary" onClick={() => scrollToForm()}>
                {t("Vai al form")} <Icon name="fal:hand-point-down" size="s" style={{ marginLeft: "1rem" }} />
              </div>
            </React.Fragment>
          )
        }
      } else {
        barHeader = (
          <React.Fragment>
            Leggi le istruzioni e compila il form a fondo pagina per partecipare{" "}
            {{ Servizio: "al servizio", Auto: "al gruppo d'acquisto", Convenzione: "alla convenzione" }[declination]}
            {/* <AnchorLink to="#form" title="Our team"> */}
            <div className="btn btn--primary" onClick={() => scrollToForm()}>
              {t("Vai al form")} <Icon name="fal:hand-point-down" size="s" style={{ marginLeft: "1rem" }} />
            </div>
            {/* </AnchorLink> */}
          </React.Fragment>
        )
        borderColor = "orange"
      }
    } else {
      switch (currentStep) {
        case 1:
          //---------------------------------------------------------------------------------------------------------------------------------------------------
          // Step 1

          if (reachedStep > 1) {
            if (reachedStep < 4) {
              barHeader = (
                <React.Fragment>
                  Stai partecipando a {{ Servizio: "questo servizio", Auto: "questo gruppo d'acquisto", Convenzione: "questa convenzione" }[declination]},
                  prosegui con lo step {reachedStep}
                  <div className="btn btn--primary" onClick={() => setCurrentStep(reachedStep)}>
                    Vai allo step {reachedStep} <Icon name="fal:hand-point-right" size="s" style={{ marginLeft: "1rem" }} />
                  </div>
                </React.Fragment>
              )
              borderColor = "green"
            }
          } else if (isFuture(participationStartDate) || !participationStartDate) {
            barHeader = (
              <React.Fragment>
                Attendi l'apertura di questo step per compilare il form a fondo pagina per partecipare
                <div className="btn btn--primary btn--disabled">
                  {t("Vai al form")} <Icon name="fal:hand-point-down" size="s" style={{ marginLeft: "1rem" }} />
                </div>
              </React.Fragment>
            )
            borderColor = "grey"
          } else if (isPast(participationEndDate)) {
            switch (declination) {
              case "Servizio":
                barHeader = (
                  <React.Fragment>
                    Questo servizio è chiuso, ma potrebbe essercene uno simile già aperto!
                    <Link to="ecoverso-plus" className="btn btn-primary">
                      {t("Vai ai Servizi Plus")}
                    </Link>
                  </React.Fragment>
                )
                break
              case "Convenzione":
                barHeader = (
                  <React.Fragment>
                    Questa convenzione è chiusa, ma potrebbe essercene uno simile già aperta!
                    <Link to="ecoverso-plus" className="btn btn-primary">
                      {t("Vai ai Servizi Plus")}
                    </Link>
                  </React.Fragment>
                )
                break
              case "Auto":
                barHeader = (
                  <React.Fragment>
                    Questo gruppo d'acquisto è chiuso, ma potrebbe essercene uno simile già aperto!
                    <Link to="groups" className="btn btn-primary">
                      {t("Vai ai gruppi d'acquisto")}
                    </Link>
                  </React.Fragment>
                )
                break
            }
            borderColor = "red"
          } else {
            barHeader = (
              <React.Fragment>
                Leggi le istruzioni e compila il form a fondo pagina per partecipare{" "}
                {{ Servizio: "al servizio", Auto: "al gruppo d'acquisto", Convenzione: "alla convenzione" }[declination]}
                {/* <AnchorLink to="#form" title="Our team"> */}
                <div className="btn btn--primary" onClick={() => scrollToForm()}>
                  {t("Vai al form")} <Icon name="fal:hand-point-down" size="s" style={{ marginLeft: "1rem" }} />
                </div>
                {/* </AnchorLink> */}
              </React.Fragment>
            )
            borderColor = "orange"
          }
          break

        case 2:
          //---------------------------------------------------------------------------------------------------------------------------------------------------
          // Step 2

          if (reachedStep < 2) {
            barHeader = (
              <React.Fragment>
                Per proseguire devi prima completare lo step {reachedStep}
                <div className="btn btn--primary" onClick={() => setCurrentStep(reachedStep)}>
                  Vai allo step {reachedStep} <Icon name="fal:hand-point-left" size="s" style={{ marginLeft: "1rem" }} />
                </div>
              </React.Fragment>
            )
            borderColor = "orange"
          } else if (reachedStep > 2) {
            if (reachedStep < 4) {
              if (userInGroupData.notInterestInBuying) {
                borderColor = "orange"
                barHeader = (
                  <React.Fragment>
                    Hai scelto solo informazioni, se cambi idea torna al form qui sotto
                    <div className="btn btn--primary" onClick={() => setCurrentStep(reachedStep)}>
                      Vai allo step {reachedStep} <Icon name="fal:hand-point-right" size="s" style={{ marginLeft: "1rem" }} />
                    </div>
                  </React.Fragment>
                )
              } else {
                barHeader = (
                  <React.Fragment>
                    Hai già effettuato la tua scelta, prosegui con lo step {reachedStep}
                    <div className="btn btn--primary" onClick={() => setCurrentStep(reachedStep)}>
                      Vai allo step {reachedStep} <Icon name="fal:hand-point-right" size="s" style={{ marginLeft: "1rem" }} />
                    </div>
                  </React.Fragment>
                )
                borderColor = "green"
              }
            }
          } else if (isFuture(choosingStartDate) || !choosingStartDate) {
            barHeader = (
              <React.Fragment>
                Attendi l'apertura di questo step per compilare il form a fondo pagina
                <div className="btn btn--primary btn--disabled">
                  {t("Vai al form")} <Icon name="fal:hand-point-down" size="s" style={{ marginLeft: "1rem" }} />
                </div>
              </React.Fragment>
            )
            borderColor = "grey"
          } else if (isPast(choosingEndDate)) {
            switch (declination) {
              case "Servizio":
                barHeader = (
                  <React.Fragment>
                    Questo servizio è chiuso, ma potrebbe essercene uno simile già aperto!
                    <Link to="ecoverso-plus" className="btn btn-primary">
                      {t("Vai ai Servizi Plus")}
                    </Link>
                  </React.Fragment>
                )
                break
              case "Convenzione":
                barHeader = (
                  <React.Fragment>
                    Questa convenzione è chiusa, ma potrebbe essercene uno simile già aperta!
                    <Link to="ecoverso-plus" className="btn btn-primary">
                      {t("Vai ai Servizi Plus")}
                    </Link>
                  </React.Fragment>
                )
                break
              case "Auto":
                barHeader = (
                  <React.Fragment>
                    Questo gruppo d'acquisto è chiuso, ma potrebbe essercene uno simile già aperto!
                    <Link to="groups" className="btn btn-primary">
                      {t("Vai ai gruppi d'acquisto")}
                    </Link>
                  </React.Fragment>
                )
            }
            borderColor = "red"
          } else {
            barHeader = (
              <React.Fragment>
                Leggi le istruzioni e compila il form a fondo pagina per proseguire
                <div className="btn btn--primary" onClick={() => scrollToForm()}>
                  {t("Vai al form")} <Icon name="fal:hand-point-down" size="s" style={{ marginLeft: "1rem" }} />
                </div>
              </React.Fragment>
            )
            borderColor = "orange"
          }
          break

        case 3:
          //---------------------------------------------------------------------------------------------------------------------------------------------------
          // Step 3

          if (reachedStep < 3) {
            barHeader = (
              <React.Fragment>
                Per proseguire devi prima completare lo step {reachedStep}
                <div className="btn btn--primary" onClick={() => setCurrentStep(reachedStep)}>
                  Vai allo step {reachedStep} <Icon name="fal:hand-point-left" size="s" style={{ marginLeft: "1rem" }} />
                </div>
              </React.Fragment>
            )
            borderColor = "orange"
          } else if (reachedStep > 3) {
            barHeader = <React.Fragment>Hai confermato la tua scelta</React.Fragment>
            borderColor = "green"
            if (userInGroupData.definitelyNotInterestedInBuying) {
              borderColor = "orange"
              barHeader = (
                <React.Fragment>
                  Hai scelto di non acquistare, se cambi idea torna al form
                  <div className="btn btn--primary" onClick={() => scrollToForm()}>
                    {t("Vai al form")} <Icon name="fal:hand-point-down" size="s" style={{ marginLeft: "1rem" }} />
                  </div>
                </React.Fragment>
              )
            }
          } else if (isFuture(confirmationStartDate) || !confirmationStartDate) {
            barHeader = (
              <React.Fragment>
                Attendi l'apertura di questo step per compilare il form a fondo pagina
                <div className="btn btn--primary btn--disabled">
                  {t("Vai al form")} <Icon name="fal:hand-point-down" size="s" style={{ marginLeft: "1rem" }} />
                </div>
              </React.Fragment>
            )
            borderColor = "grey"
          } else if (isPast(confirmationEndDate)) {
            switch (declination) {
              case "Servizio":
                barHeader = (
                  <React.Fragment>
                    Questo servizio è chiuso, ma potrebbe essercene uno simile già aperto!
                    <Link to="ecoverso-plus" className="btn btn-primary">
                      {t("Vai ai Servizi Plus")}
                    </Link>
                  </React.Fragment>
                )
                break
              case "Convenzione":
                barHeader = (
                  <React.Fragment>
                    Questa convenzione è chiusa, ma potrebbe essercene uno simile già aperta!
                    <Link to="ecoverso-plus" className="btn btn-primary">
                      {t("Vai ai Servizi Plus")}
                    </Link>
                  </React.Fragment>
                )
                break
              case "Auto":
                barHeader = (
                  <React.Fragment>
                    Questo gruppo d'acquisto è chiuso, ma potrebbe essercene uno simile già aperto!
                    <Link to="groups" className="btn btn-primary">
                      {t("Vai ai gruppi d'acquisto")}
                    </Link>
                  </React.Fragment>
                )
            }
            borderColor = "red"
          } else {
            barHeader = (
              <React.Fragment>
                Leggi le istruzioni e compila il form a fondo pagina per proseguire
                <div className="btn btn--primary" onClick={() => scrollToForm()}>
                  {t("Vai al form")} <Icon name="fal:hand-point-down" size="s" style={{ marginLeft: "1rem" }} />
                </div>
              </React.Fragment>
            )
            borderColor = "orange"
          }
          break
      }
    }
  }

  return barHeader ? (
    <div className="row row--full pt-none pb-none mt-none mb-none">
      <div className="group__header-bar" style={{ borderColor }}>
        {barHeader}
      </div>
    </div>
  ) : null
}

export default InContentTopBar
