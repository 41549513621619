import React, { useContext, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import * as actions from "../store/actions"
import { graphql } from "gatsby"
import gql from "graphql-tag"

import { getAppSyncClientContext } from "../ApolloWrapper"
import { useMutation, useQuery } from "@apollo/client"

import GridLayout from "../Layout/GridLayout"
import Img from "gatsby-image/withIEPolyfill"
import { FormatDate, Link, useI18next } from "../i18n"
import siteMap from "../sitemap"

import { Icon, Title, Breadcrumbs, Image, ImageData } from "../Layout/Components"
import { Popover } from "antd"
import { DynamicRow, RowData } from "../rows/DynamicRow"
import Steps, { StepProps } from "../Layout/Components/Steps"

import { Seo, StructuredData } from "../seo"
import { Car, Product, WithContext } from "schema-dts"
import { faGameConsoleHandheld } from "@fortawesome/pro-duotone-svg-icons"
import { buying_group, user_in_buying_group } from "../API"
import { differenceInCalendarDays, isFuture, isPast, parseISO } from "date-fns"
import { getArgumentValues } from "graphql/execution/values"

import GroupSteps from "./groupElements/GroupSteps"
import InContentTopBar from "./groupElements/InContentTopBar"
import { createUserInBuyingGroup, updateUserInBuyingGroup } from "../graphql/mutations"
import { GET_PERSONAL_DETAILS, UPDATE_PERSONAL_DETAILS, getBuyingGroup, getBuyingGroupAsParticipant, searchUserInBuyingGroups } from "../graphql.my/queries"

import { firstName, email, readPrivacy, mobile, state } from "../forms/fields/user"
import FormGenerator from "../Layout/Forms/FormGenerator"
import WPComments from "../rows/WPComments"
import Forms from "./groupElements/Forms"
import ManageGroup from "../User/Profile/Components/ManageGroup"

//----------------------------------------------------------------------------------------------------------------

export type buyingGroupData = {
  id: string
  idOrganization: string
  name: string
  description: string
  uidCMS: string
  pubblicationStartDate: string
  registrationStartDate: string
  participationStartDate: string
  choosingStartDate: string
  registrationEndDate: string
  confirmationStartDate: string
  resellerFlowStartDate: string
  pubblicationEndDate: string
  participationEndDate: string
  choosingEndDate: string
  confirmationEndDate: string
  resellerFlowEndDate: string
  maxChoosableVariants: string
  uniteChooseAndConfirm: string
  declination: string
  isOnlyUpselling: string
  GoodsOrServices: [
    {
      GoodOrService: {
        name: string
        slug: string
        GoodOrServiceType: {
          name: string
          slug: string
          description: string
          userChoosingCustomFieldsDef: string
          userConfirmationCustomFieldsDef: string
        }
      }
      Variants: [
        {
          id: string
          code: string
          name: string
          description: string
          maxQuantity: string
          discountAmountEach: string
          discountPercent: string
        }
      ]
    }
  ]
  GoodOrServiceVariants: [
    {
      id: string
      code: string
      name: string
      description: string
      maxQuantity: string
      discountAmountEach: string
      discountPercent: string
      GoodOrService: {
        name: string
        slug: string
        GoodOrServiceType: {
          name: string
          slug: string
          description: string
          userChoosingCustomFieldsDef: string
          userConfirmationCustomFieldsDef: string
        }
      }
    }
  ]
  Resellers: [
    {
      Organization: {
        id: string
        name: string
        state: string
        city: string
      }
    }
  ]
}

//----------------------------------------------------------------------------------------------------------------

//todo: ragionare su possibile structuredData per i gruppi di acquisto (vedi wpCar.tsx + https://developers.google.com/search/docs/data-types/article)

export default (props) => {
  const { t } = useI18next()
  const dispatch = useDispatch()
  const wpGroup = props.data.wordPress.buyingGroup

  //console.log("Data: ", props.data)
  //console.log("Props: ", props)
  //console.log("Articles: ", pressArticles)

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
  const userId = useSelector((state) => state.auth.payload?.userData?.userId)
  const userFirstName = useSelector((state) => state.auth.payload?.userData?.firstName)
  const isAdmin = useSelector((state) => state.auth.payload.role?.includes("Admin") || state.auth.payload.role?.includes("SuperAdmin"))

  const [currentStep, setCurrentStep] = useState(0)
  const [reachedStep, setReachedStep] = useState(0)
  const [rdbGroupData, setRdbGroupData] = useState(props.data.appsync.getBuying_group as buyingGroupData)
  const [userInGroupData, setUserInGroupData] = useState(null as user_in_buying_group | null)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(null)
  const [isFormLoading, setIsFormLoading] = useState(false)

  const rowsPerStep = [
    Array.isArray(wpGroup.details.rowsStep0) ? wpGroup.details.rowsStep0 : [],
    Array.isArray(wpGroup.details.rowsStep1) ? wpGroup.details.rowsStep1 : [],
    Array.isArray(wpGroup.details.rowsStep2) ? wpGroup.details.rowsStep2 : [],
    Array.isArray(wpGroup.details.rowsStep3) ? wpGroup.details.rowsStep3 : [],
  ]

  const scrollToTop = () => {
    var element = document.querySelector(".hero__group__steps")
    if (element) element.scrollIntoView({ behavior: "smooth", block: "center" })
  }

  let choosingStartDate = rdbGroupData.choosingStartDate != null ? parseISO(rdbGroupData.choosingStartDate) : null
  let confirmationStartDate = rdbGroupData.confirmationStartDate != null ? parseISO(rdbGroupData.confirmationStartDate) : null

  const setCurrentStepWrapper = (step) => {
    const originalStep = currentStep
    if (step > 1 && isFuture(choosingStartDate)) step = 1
    if (step > 2 && isFuture(confirmationStartDate)) step = 2
    if (rdbGroupData.uniteChooseAndConfirm && step > 1) step = 1

    // If we change step generate a virtual page view
    if (step != originalStep) {
      // Send event to GTM
      window?.dataLayer?.push({ event: "simulatedPageView", urlSuffix: `step-${step}` })
    }

    setCurrentStep(step)
  }

  // -------------------------------------------------------------------------------------------------------------------
  // --- QUERIES -------------------------------------------------------------------------------------------------------

  const getAppsyncClient = useContext(getAppSyncClientContext)

  // --- Group ---------------------------------------------------------------------------------------------------------

  // Fetch Updated RDB Group Data
  const {
    loading: rdbGroupLoading,
    error: rdbGroupError,
    data: rdbGroupDataFetch,
  } = useQuery(gql(userInGroupData ? getBuyingGroupAsParticipant : getBuyingGroup), {
    variables: { id: props.pageContext.rdbId },
    client: getAppsyncClient(isLoggedIn),
    // fetchPolicy: "no-cache",
  })

  // Handle Updated RDB Group Data
  useEffect(() => {
    // Update comments with the ones from wordpress ;-)
    if (!rdbGroupLoading && !rdbGroupError) {
      setRdbGroupData(rdbGroupDataFetch.getBuying_group)
      //console.log("RDB Group data: ", rdbGroupDataFetch.getBuying_group)
    }
  }, [rdbGroupDataFetch, rdbGroupError, rdbGroupLoading])

  // --- User in Buying Group ------------------------------------------------------------------------------------------

  // Fetch Updated User In Group Group Data
  const {
    loading: userInGroupLoading,
    error: userInGroupError,
    data: userInGroupDataFetch,
    refetch: userInGroupDataRefetch,
  } = useQuery(gql(searchUserInBuyingGroups), {
    variables: { user_in_buying_group: { idBuyingGroup: props.pageContext.rdbId, idUser: userId } },
    skip: !isLoggedIn || !userId,
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    if (!userInGroupLoading && !userInGroupError && userInGroupDataFetch != null) {
      if (userInGroupDataFetch.searchUser_in_buying_groups != null) {
        setUserInGroupData(userInGroupDataFetch.searchUser_in_buying_groups[0])
        const userData = userInGroupDataFetch.searchUser_in_buying_groups[0]
        // User is in grup, determine currently reached which step
        //console.log("User in group: ", userInGroupDataFetch.searchUser_in_buying_groups[0])
        setCurrentStepWrapper(userData.choosingDate ? 3 : userData.participationDate ? 2 : 1)
        setReachedStep(userData.confirmationDate ? 4 : userData.choosingDate ? 3 : userData.participationDate ? 2 : 1)
      } else {
        setUserInGroupData(null)
        //console.log("User not in group. ", userInGroupDataFetch)
      }
    }
    //console.log("User in group: ", userInGroupDataFetch, userInGroupLoading, userInGroupError)
  }, [userInGroupDataFetch, userInGroupError, userInGroupLoading])

  // -------------------------------------------------------------------------------------------------------------------
  // --- MUTATIONS -----------------------------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------------------------------------------
  // Registration

  const [registerToGroup, { loading: registrationLoading }] = useMutation(gql(createUserInBuyingGroup), {
    onCompleted: () => {
      userInGroupDataRefetch()
      dispatch(
        actions.notificationAdd({
          message: "Registrazione avvenuta con successo",
          variant: "SmileIcon",
          duration: 30,
        })
      )
      scrollToTop()
    },
    onError: (error) => {
      dispatch(actions.notificationAdd({ message: t("Registration Error"), variant: "DangerIcon" }))
      console.log("Registration Error", error)
      return null
    },
  })

  const onRegister = async () => {
    setIsLoading(true)
    await registerToGroup({
      variables: { createUser_in_buying_groupInput: { idBuyingGroup: props.pageContext.rdbId, idUser: userId } },
    })

    // Send event to GTM
    window?.dataLayer?.push({
      event: "group-step-completed",
      step: "0",
      groupUID: rdbGroupData.uidCMS,
      groupName: rdbGroupData.name,
      groupParticipationScope: "informazioni",
      groupDeclination: rdbGroupData.declination,
    })
  }

  // -------------------------------------------------------------------------------------------------------------------
  // Merge all loadings
  useEffect(() => {
    setIsLoading(rdbGroupLoading || userInGroupLoading || registrationLoading || isFormLoading)
  }, [rdbGroupLoading, userInGroupLoading, registrationLoading, isFormLoading])

  // Merge all errors
  useEffect(() => {
    if (rdbGroupError != null || userInGroupError != null) {
      setIsError(true)
      dispatch(actions.notificationAdd({ message: t("API Error"), variant: "DangerIcon" }))
      console.log("Error (Group): ", rdbGroupError)
      console.log("Error (User In Group): ", userInGroupError)
    }
  }, [rdbGroupError, userInGroupError])

  // -------------------------------------------------------------------------------------------------------------------
  // --- Render the page -----------------------------------------------------------------------------------------------
  return (
    <GridLayout>
      <Seo title={wpGroup.seo.title} description={wpGroup.seo.metaDesc} image={wpGroup.details.imageHero?.imageFile?.publicURL} />
      <Breadcrumbs data={[{ to: "/", label: t("Home") }, { to: "groups", label: t("Gruppi d'acquisto") }, { label: wpGroup.title }]} />

      <div className="hero hero__group">
        <div className="hero__group__cover">
          <Image file={wpGroup.details.imageHero} type="cover" className="hero__group__image" />
          <div className="hero__group__overlay"></div>
        </div>
        {isAdmin ? (
          <div className="hero__group__admin">
            <ManageGroup buyingGroupId={rdbGroupData.id} as="admin">
              <a className="btn btn--primary btn-sm btn-admin">Gestisci gruppo</a>
            </ManageGroup>
          </div>
        ) : null}
        <div className="hero__group__holder">
          <div
            className="hero__group__category"
            dangerouslySetInnerHTML={{
              __html: { Servizio: "Servizio Plus", Auto: "Gruppo d'Acquisto auto", Convenzione: "Convenzione" }[rdbGroupData.declination],
            }}
          ></div>
          <h1 className="hero__group__title" dangerouslySetInnerHTML={{ __html: wpGroup.title }}></h1>
        </div>
        <div className="hero__group__steps">
          {!isLoading ? (
            <GroupSteps
              currentStep={currentStep}
              rdbGroupData={rdbGroupData}
              userInGroupData={userInGroupData}
              onRegister={onRegister}
              setCurrentStep={setCurrentStepWrapper}
            />
          ) : (
            <img src="/img/ecoverso.spinner.gif" style={{ height: "5rem" }} />
          )}
        </div>
      </div>

      <div className="row row--center pt-none">
        <div className="group">
          {!isLoading ? (
            <InContentTopBar
              currentStep={currentStep}
              rdbGroupData={rdbGroupData}
              userInGroupData={userInGroupData}
              onRegister={onRegister}
              setCurrentStep={setCurrentStepWrapper}
              scrollToForm={() => {
                var element = document.querySelector("#form")
                if (element) element.scrollIntoView({ behavior: "smooth", block: "center" })
              }}
            />
          ) : null}

          {rowsPerStep[currentStep].map((row, i) => (
            <DynamicRow data={row} graphQLData={props.data} key={i} />
          ))}

          {currentStep >= 1 ? (
            <Forms
              currentStep={currentStep}
              rdbGroupData={rdbGroupData}
              userInGroupData={userInGroupData}
              setCurrentStep={setCurrentStepWrapper}
              scrollToTop={scrollToTop}
              setIsLoading={setIsFormLoading}
              userInGroupDataRefetch={userInGroupDataRefetch}
            />
          ) : null}

          {currentStep >= 1 ? (
            <React.Fragment>
              <div className="row row--full row__background--none mb--xl pb-none mt--m">
                <div className="row row--center row--gap-big">
                  <div className="col--12 col--va-top">
                    <div className="heading__container heading__container--left mb-xs">
                      <h1 className="heading heading-title mb-none">Bacheca</h1>
                    </div>
                    <div>
                      <p>
                        Di seguito trovi la bacheca di questo{" "}
                        {{ Servizio: "Servizio Plus", Auto: "Gruppo d'Acquisto", Convenzione: "Convenzione" }[rdbGroupData.declination]}, attraverso la quale
                        puoi comunicare sia con noi coordinatori che con gli altri partecipanti.
                      </p>
                      <p>Scrivi qui le tue domande, verificando che non abbiano già trovato risposta nei commenti precedenti.</p>
                      <p>Ti risponderemo appena possibile.</p>
                    </div>
                  </div>
                </div>
              </div>
              <WPComments postId={wpGroup.databaseId} comments={wpGroup.comments.nodes} />
            </React.Fragment>
          ) : null}
        </div>
      </div>

      {/* {currentStep == 0 ? (
        <React.Fragment>
          <div className="row row--center pt-none">
            <div className="group">
              {rowsPerStep[1].map((row, i) => (
                <DynamicRow data={row} graphQLData={props.data} key={i} />
              ))}
            </div>
          </div>
          <div className="row row--center pt-none">
            <div className="group">
              {rowsPerStep[2].map((row, i) => (
                <DynamicRow data={row} graphQLData={props.data} key={i} />
              ))}
            </div>
          </div>
          <div className="row row--center pt-none">
            <div className="group">
              {rowsPerStep[3].map((row, i) => (
                <DynamicRow data={row} graphQLData={props.data} key={i} />
              ))}
            </div>
          </div>{" "}
        </React.Fragment>
      ) : null} */}
    </GridLayout>
  )
}

// ------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------

export const query = graphql`
  query ($rdbId: String!, $id: ID!, $tags: [String!], $buyingGroupsWpIds: [ID!], $languageCode: Wordpress_LanguageCodeFilterEnum!, $relatedPostsLimit: Int) {
    appsync {
      getBuying_group(id: $rdbId) {
        id
        pubblicationStartDate
        pubblicationEndDate
        registrationStartDate
        registrationEndDate
        participationStartDate
        participationEndDate
        choosingStartDate
        choosingEndDate
        confirmationStartDate
        confirmationEndDate
        resellerFlowStartDate
        resellerFlowEndDate
        maxChoosableVariants
        isOnlyUpselling
        uniteChooseAndConfirm
        declination
        uidCMS
        GoodsOrServices {
          GoodOrService {
            name
            slug
          }
        }
      }
      ...dbBuyingGroupsData
    }
    wordPress {
      buyingGroup(id: $id) {
        id
        databaseId
        slug
        title
        language {
          slug
        }
        seo {
          title
          metaDesc
        }
        translations {
          slug
          language {
            slug
          }
        }
        tags {
          nodes {
            slug
            name
          }
        }
        brands {
          nodes {
            name
            slug
            details {
              logo {
                ...imageData
              }
            }
          }
        }
        details {
          uid
          coloring
          imageHero {
            ...imageData
          }
          rowsStep0 {
            ...buyingGroupStep0Rows
          }
          rowsStep1 {
            ...buyingGroupStep1Rows
          }
          rowsStep2 {
            ...buyingGroupStep2Rows
          }
          rowsStep3 {
            ...buyingGroupStep3Rows
          }
        }
        commentCount
        commentStatus
        ...buyingGroupComments
      }
      ...pageAccessoryData
    }
  }
`
